<template>
  <base-material-card
    id="tattooer-list"
    :title="$t('list', { name: $tc('tattooer', 2) })"
    icon="mdi-account-edit"
  >
    <InvoiceForm
      :invoice="invoice"
      @validated="save()"
      @cancel="$router.back()"
    />
  </base-material-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewInvoiceForm",
  components: {
    InvoiceForm: () => import("@/components/invoices/Form"),
  },
  data() {
    return {
      invoice: {
        type: "custom",
        items: [{ selected: true }],
        taxes: [],
      },
    };
  },
  mounted() {
    this.invoice.invoice_type = this.$route.params.invoice_type;
  },
  methods: {
    ...mapActions("invoices", ["addInvoice"]),
    save() {
      this.addInvoice(this.invoice).then(() => {
        this.$alert(this.$t("save_ok"));
        this.$router.back();
      });
    },
  },
};
</script>
